import React from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import menuChart1 from "../../../Assets/wed-menu.png";
import menuChart2 from "../../../Assets/wed-trad.png";
import txMapWhite from "../../../Assets/tx-map-white.png";
import Image from "mui-image";
import cateringMenu from "../../../Assets/wed-menu-chart.png";
import menuSp from "../../../Assets/wed-special.jpg";

const MenuContent = () => {
  return (
    <Box sx={{ py: 5 }}>
      {/* <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "auto" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuSp}
          />
        </Card>
      </Box> */}
      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "auto" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={cateringMenu}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color="text.secondary">
              Enjoy the best BBQ meal
            </Typography>
            <Button
              href="https://drive.google.com/file/d/1Ft0HCgkPaM8_j34K2BRSwF_wYChenf15/view?usp=share_link"
              sx={{
                textAlign: "center",
                width: "50%",
              }}
              variant="outlined"
              size="large"
              color="primary"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "50%" },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "130vh" },
              objectFit: { xs: "fill", md: "fill" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuChart1}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color="text.secondary">
              Enjoy the best BBQ meal
            </Typography>
            <Button
              href="https://drive.google.com/file/d/1smHEkZDqFecfVvy1KaOASbFw0KK2qSSw/view?usp=sharing"
              sx={{ textAlign: "center", width: "50%" }}
              variant="outlined"
              size="large"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ p: { xs: 0, md: 4 } }}>
        <Image src={txMapWhite} style={{ height: "300px", width: "300px" }} />
      </Box>
      <Box
        sx={{
          p: 2,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Typography sx={{ p: 2 }} variant="h2">
          Available Now <br /> Nov 14, 2024 - Jan 1, 2025
        </Typography> */}
        <Typography sx={{ p: 2, width: "60%" }} variant="h4">
          Fine Authentic Texas BBQ Holiday Meals are available with 48hrs
          advance notice.
        </Typography>
      </Box>
      {/* <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "auto" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuChart2}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              href="https://drive.google.com/file/d/1dnTS9cOzn-sjT-sSYGyewx_MF4UvqWEW/view?usp=drive_link"
              sx={{
                textAlign: "center",
                width: "50%",
              }}
              variant="outlined"
              size="large"
              color="primary"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box> */}
    </Box>
  );
};

export default MenuContent;
